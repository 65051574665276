@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/*  "Poppins" */
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&display=swap");
/*  "Cinzel" */

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;

}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #a27593ae;
  width: 0;

}

body::-webkit-scrollbar-thumb {
  background: #f6e4f0;
  border-radius: 10px;
  border: none;
  width: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #f0bddf;
}

/*
=============== 
Var Styles
===============
*/
:root {
  --color-admin-primary: #3d3e9c;
  --color-admin-primary2: #5456ce;
  --color-admin-dark: #141078;
  --color-admin-light: #a2a5e8;
  --color-admin-light2: #e8eefb;
  --color-admin-light3: #c7cbd6;
  --transition: all 0.3s linear;
  --radius: 0.25rem;
  --radius: 0.25rem;
  --primary-shadow: 0 5px 25px #13107823;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/*
=============== 
Global Styles
===============
*/
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  visibility: hidden;
}

a {
  text-decoration: none;
  color: #333;
}

a:focus {
  outline: none;
}

button:focus {
  outline: none;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

/*-------------------------------------------------- Navbar-start --------------------------------------------------*/
.container .navbar {
  position: fixed;
  width: 100%;
  /* border: 1px #888 solid; */
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 10;
  background-color: transparent;
  transition: .5s;
  top: 0;
  overflow: hidden;
}

.container .navbar-on-search {
  background: #fff;
}

.container .navbar-in-out {
  width: 100%;
  /* border: 1px #888 solid; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 30px 0;
  background-color: transparent;
  transition: .5s;
  position: relative;
}

.container .navbar-in-out .humberger {
  position: absolute;
  left: 85%;
  width: 40px;
  height: 40px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  transform: translateY(18px);
  display: none;

}

.container .navbar-in-out .humberger h2 {
  display: flex;
  color: #222;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container .navbar-in-out .Search-icon {
  position: absolute;
  left: 10%;
  width: 40px;
  height: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  transform: translateY(20px);
  display: none;
}

.container .navbar-in-out .Search-icon h2 {
  display: flex;
  color: #222;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container .navbar-in-out .navbar-in {
  width: 100%;
  height: 70px;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
}

.container .navbar-in-out .navbar-in .in-left {
  display: flex;
  align-items: center;
  width: 45%;
  overflow: hidden;
  box-sizing: border-box;
  /* display: none; */
}

.container .navbar-in-out .navbar-in .in-left .li,
.container .navbar-in-out .navbar-in .in-right .li {
  margin: 10px;
  font-size: 17px;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  box-sizing: border-box;
  transition: var(--transition);
  cursor: pointer;
}

.container .navbar-in-out .navbar-in .in-left .li2 a, .in-right .li2, .in-right .li2 a {
  color: #faebe3;
}

.container .navbar-in-out .navbar-in .in-right .li.Shopify {
  font-size: 19px;
  position: relative;
  overflow: visible;
}

.container .navbar-in-out .navbar-in .in-right .li.Shopify .current {
  position: absolute;
  inset: 0;
  left: 80%;
  top: -20%;
  width: 1vw;
  height: 1vw;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .7vw;
  font-weight: 400;
  color: #333;
  z-index: 1;
  padding: 0;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container .navbar-in-out .navbar-in .in-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5px;
  overflow: hidden;
  box-sizing: border-box;
  width: 20%;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;

}

.container .navbar-in-out .navbar-in .in-center img {
  width: 56px;
  height: 42px;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .navbar-in-out .navbar-in .in-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  width: 45%;
  overflow: hidden;
  /* display: none; */
}

.container .navbar-in-out .navbar-out {
  position: relative;
  width: 100%;
  /* height: 200px; */
  height: 0;
  box-sizing: border-box;
  transition: .5s;
  overflow: hidden;
}

.container .navbar-in-out .media-navbar {
  width: 100%;
  height: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  inset: 0;
  transition: .5s;
  z-index: 2;
  overflow: hidden;

}

.container .navbar-in-out .media-navbar .li {
  width: 100%;
  height: 55px;
  display: flex;
  font-size: 25px;
  font-weight: 500;
  color: #333;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.container .navbar-in-out .navbar-out form {
  width: 100%;
  height: auto;
  padding: 15px 100px;
  position: relative;
  padding-bottom: 0px;
}

.container .navbar-in-out .navbar-out form input {
  width: 100%;
  height: 100px;
  border: none;
  padding: 0px 20px;
  font-size: 30px;
  background: none;
  color: #555;
}

.container .navbar-in-out .navbar-out form input::-webkit-input-placeholder {
  color: #999;
}

.container .navbar-in-out .navbar-out form input::-moz-placeholder {
  color: #999;
}

.container .navbar-in-out .navbar-out form input:-ms-input-placeholder {
  color: #999;
}

.container .navbar-in-out .navbar-out form input::-ms-input-placeholder {
  color: #999;
}

.container .navbar-in-out .navbar-out form input::placeholder {
  color: #999;
}

.container .navbar .forSearch {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -5;
  inset: 0;
  display: none;
  background: transparent;
  opacity: 0;
  transition: .5s;
  cursor: not-allowed;
}

.container .navbar .forSearch.isSearch {
  background: rgba(51, 51, 51, 0.514);
  z-index: -1;
  display: block;
  opacity: 1;
}

.container .navbar-in-out .navbar-out .all-data-Search {
  width: 100%;
  padding: 0px 50px 20px;
  max-height: 460px;
  overflow: hidden;
}

.container .navbar-in-out .navbar-out .all-data-Search h2 {
  width: 100%;
  height: 45px;
  font-size: 25px;
  font-weight: 400;
  padding: 0px 65px;
  color: #555;
  margin: 5px;
  transition: .3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container .navbar-in-out .navbar-out .all-data-Search h2:hover {
  background-color: rgba(34, 34, 34, 0.11);
}

.container .navbar-in-out .navbar-out .all-data-Search a:focus h2 {
  background-color: rgba(34, 34, 34, 0.11);
}

/*-------------------------------------------------- Navbar-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- Home-start --------------------------------------------------*/
/*-------------------------------------------------- fill-start --------------------------------------------------*/
.container .the-fill {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.container .the-fill .fill-content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 2;
  height: 100%;
}

.container .the-fill .fill-content h1 {
  font-family: "Cinzel";
  font-size: 7vw;
  text-align: center;
  font-weight: 400;
  color: #444;
  letter-spacing: 12px;
  text-transform: uppercase;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.container .the-fill .fill-content h1 .h1-animation-before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  z-index: 1;
}

.container .the-fill .fill-content h1 .h1-animation-after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #222;
  z-index: 2;
}

.container .the-fill .fill-content h3 {
  text-align: center;
  font-size: 1.3vw;
  text-transform: uppercase;
  color: #555;
  font-weight: 600;
  letter-spacing: 7px;
  box-sizing: border-box;
  position: absolute;
  top: 82%;
  overflow: hidden;

}

.container .the-fill .fill-content h3 .h3-animation-before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  inset: 0;
  z-index: 1;

}

.container .the-fill .fill-content h3 .h3-animation-after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #222;
  inset: 0;
  z-index: 2;

}

.container .the-fill .fill-page {
  position: absolute;
  width: 100.1%;
  height: 100.1%;
  background: url(./images/bijauxPrincipale.jpg) no-repeat;
  background-position: center;
  background-size: 50%;
  opacity: 0;
  transform: translateY(-600px);
}

/* .container .the-fill .fill-page::before{
  content: '';
  position: absolute;
  inset: 0;
  margin: auto auto;
  background-color: #f2f2f2;
  width: 50%;
  height:50%;
} */
/* .container .the-fill .fill-page::after{
  content: '';
  position: absolute;
  inset: 0;
  margin: auto auto;
  background-color: wheat;
  width: 50%;
  height:50%;
} */
/* .container .the-fill .fill-page:hover{
  background-size: 100%;

} */

.container .the-fill .left-fill {
  position: absolute;
  width: 40%;
  height: 100%;
  z-index: 1;
  background: #f2f2f2;
  overflow: hidden;
}

.container .the-fill .right-fill {
  width: 40%;
  height: 100%;
  z-index: 1;
  background: #f2f2f2;
  left: 60%;
  position: absolute;

}

.container .the-fill .top-fill {
  width: 100%;
  height: 40%;
  z-index: 1;
  background: #f2f2f2;
  top: 75%;
  position: absolute;
}

.container .the-fill .bottom-fill {
  width: 100%;
  height: 25%;
  z-index: 1;
  background: #f2f2f2;
  position: absolute;
}

.container .space-animation {
  height: 1500px;
  width: 100%;
}

/*-------------------------------------------------- fill-end --------------------------------------------------*/

/*-------------------------------------------------- content-start --------------------------------------------------*/

.content {
  position: relative;
  z-index: 5;
  background: #f2f2f2;
  width: 100%;
  display: flex;
  padding: 30px 10px;
  flex-direction: column;
  box-sizing: border-box;

}

.content .text-intro {
  width: 75%;
  text-align: center;
  margin: 150px auto 0;
  box-sizing: border-box;
}

.content .text-intro h1 {

  color: #616161;
  font-family: "Cinzel";
  font-size: 1.95vw;
  font-weight: 500;
  box-sizing: border-box;

}

.content .intro-photos {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: 2s;
}

.content .intro-photos .intor-img {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 350px;
  margin: 20px 0;

}

.intro-photos .img1 img, .intro-photos .img2 img, .intro-photos .img3 img, .intro-photos .img4 img {
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 8;
}

.content .intro-photos .img1 img {
  height: 300px;
  left: 200px;
  transform: translateY(-100px);
  position: relative;
  top: 200px;
}

.content .intro-photos .img2 img {
  height: 500px;
  left: 50%;
  transform: translateY(-250px);
  position: relative;
  top: -200px;
}

.content .intro-photos .img3 img {
  height: 320px;
  right: 10px;
  left: -10px;
  transform: translateY(-100px);
}

.content .intro-photos .img4 img {
  height: 450px;
  left: 70%;
  transform: translateY(-200px);

}

.content .intro-photos .to-shop-page {
  box-sizing: border-box;
  position: absolute;
  z-index: 6;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content .intro-photos .to-shop-page h4 {
  color: #bbb;
  letter-spacing: 3px;
  font-size: 1.5vw;
  text-transform: uppercase;
  font-weight: 500;
}

.content .intro-photos .to-shop-page h1 {
  font-size: 5.5vw;
  font-weight: 400;
  font-family: "Cinzel";
  text-transform: uppercase;
  color: rgb(66, 66, 66);
  cursor: pointer;
  margin: 20px 0;
}

.content .intro-photos .to-shop-page button {
  border: solid #222 2px;
  background: transparent;
  padding: 20px 50px;
  font-size: 20px;
  margin: 20px 0;
  color: #222;
  cursor: pointer;
  transition: .4s;
}

.content .intro-photos .to-shop-page button:hover {
  border: transparent solid 2px;
  color: #f2f2f2;
  background: #222;
}

/*-------------------------------------------------- content-end --------------------------------------------------*/

/*-------------------------------------------------- mini-about-start --------------------------------------------------*/

.container .to-about {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 150px 10px;
  background-color: #7a7a84;
  box-sizing: border-box;
  /* color:#faebe3; */
  z-index: 9;
}

.container .to-about h4 {
  font-size: 15px;
  font-weight: 400;
  color: #faebe3;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  letter-spacing: 2px;

}

.container .to-about h1 {
  font-size: 2.5vw;
  font-weight: 500;
  font-family: "Cinzel";
  width: 70%;
  color: #faebe3;
  margin: 10px 0;
  box-sizing: border-box;
  text-align: center;
  margin: 50px 0;
  text-transform: uppercase;

}

.container .to-about button {
  padding: 20px 50px;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 500;
  background-color: #faebe3;
  border: #faebe3 solid 3px;
  transition: var(--transition);
  box-sizing: border-box;
  text-align: center;
  color: #7a7a84;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 2px;

}

.container .to-about button:hover {
  border: solid 3px #faebe3;
  color: #faebe3;
  background-color: transparent;
}


/*-------------------------------------------------- mini-about-end --------------------------------------------------*/
/*-------------------------------------------------- foot-start --------------------------------------------------*/

.intro-foot {
  width: 100%;
  height: 920px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #222;
  overflow: hidden;
  z-index: 2;
}

.foot-about {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 20px 0;
  box-sizing: border-box;
  align-items: center;
  overflow: hidden;

}

.foot-about .khat {
  width: 2px;
  height: 70%;
  background-color: #f2ecff9a;
}

/*  nradja3 ga3 les rows fi wahda psq 3andhoum ga3 les mm css */
.about-row1, .about-row2, .about-row3 {
  width: 33%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
}

.about-row1 h2, .about-row3 h2, .about-row2 h2 {
  position: relative;
  width: 50%;
  box-sizing: border-box;
  color: #f2ecffe7;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.about-row1 h2::after, .about-row3 h2::after, .about-row2 h2::after {
  content: "";
  width: 20%;
  height: 3px;
  background-color: #f2ecffe7;
  position: absolute;
  top: 100%;
}

.about-row1 .hours-days {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

}

.about-row1 .hours-days .hours, .about-row1 .hours-days .days {
  width: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  transition: var(--transition);
}

.about-row1 .hours-days .hours h3, .about-row1 .hours-days .days h3 {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 400;
  color: #f2ecff9a;
  margin: 0;
  transition: var(--transition);
  padding: 10px;
}

.about-row1 .hours-days .hours h3:hover, .about-row1 .hours-days .days h3:hover {
  box-shadow: 0 0 10px #f2ecff54;
}

.about-row2 .categories {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

}

.about-row2 .categories h3 {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 400;
  color: #f2ecff9a;
  margin: 10px 0;

}

.about-row3 .Resources {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
}

.about-row3 .Resources h3 {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
  color: #f2ecff9a;
  margin: 10px 0;
  transition: .2s;
}

.about-row3 .Resources h3:hover {
  color: #f2ecffd2;

}

.foot-social {
  box-sizing: border-box;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-top: 1px #f2f2f26b solid;
  border-bottom: 1px #f2f2f260 solid;
  padding: 10px 0;
  overflow: hidden;

}

.foot-social .to-social {
  box-sizing: border-box;
  margin: 0 15px;
  width: 40px;
  height: 40px;
  color: #f2f2f27a;
  transition: var(--transition);
}

.foot-social .to-social:hover {
  color: #f2f2f2ea;
  transform: scale(1.1);
}

.foot-final {
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #f2f2f2a2;
  font-size: 20px;
  overflow: hidden;

}

.foot-final h5 {
  box-sizing: border-box;
  color: rgba(235, 188, 60, 0.603);
  font-size: 20px;

}

/*-------------------------------------------------- foot-end --------------------------------------------------*/
/*-------------------------------------------------- Home-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


/*-------------------------------------------------- boutique-start --------------------------------------------------*/
/*-------------------------------------------------- intro-start --------------------------------------------------*/
.container .in-boutique {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.container .in-boutique .boutique-face {
  position: relative;
  width: 100%;
  height: 950px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.container .in-boutique .boutique-face h1 {
  position: absolute;
  box-sizing: border-box;
  font-size: 11vw;
  font-family: "Cinzel";
  font-weight: 400;
  text-align: center;
  z-index: 2;
  color: #595f70;
  transform: translateY(-150px);
  overflow: hidden;
}

.container .in-boutique .boutique-face h1 .h1-animation-before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  inset: 0;

}

.container .in-boutique .boutique-face .h1-animation-after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #595f70;
  inset: 0;
}

.container .in-boutique .boutique-face img {
  box-sizing: border-box;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  width: 35%;
  height: 700px;
  transform: translateY(200px);
}

.container .in-boutique .boutique-story {
  box-sizing: border-box;
  width: 100%;
  height: 1050px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #595f70;
}

.container .in-boutique .boutique-story img {
  box-sizing: border-box;
  width: 20%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .in-boutique .boutique-story .text {
  position: relative;
  box-sizing: border-box;
  width: 45%;
  padding-left: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
}

.container .in-boutique .boutique-story p {
  position: relative;
  box-sizing: border-box;
  font-size: 25px;
  font-family: "moncef";
  font-weight: 500;
  color: #faebe3;
  font-style: italic;

}

.container .in-boutique .boutique-story a {
  position: relative;
  box-sizing: border-box;
  top: 5%;
  color: #faebe3;
  font-weight: 300;
  text-align: left;
  width: 100%;
}

/*-------------------------------------------------- intro-end --------------------------------------------------*/
/*-------------------------------------------------- Products-start --------------------------------------------------*/

.container .all-product {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: .5s;
}

.container .all-product-change {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  transition: .5s;
}

.container .all-product .category-fill {
  width: 70%;
  height: 120px;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.container .all-product-change .category-fill {
  width: 80%;
  align-items: flex-start;
}

.container .all-product .category-fill select {
  width: 250px;
  height: 48px;
  border: none;
  outline: none;
  border: 3px #595f70 solid;
  box-shadow: 5px 5px 0 #595f70;
  background-color: transparent;
  font-size: 25px;
  padding: 0 5px;
  color: #6a7081;

}

.container .all-product .category-fill select:focus {
  outline: none;
}

.container .all-product .category-fill select option {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 50px 0;
  padding: 15px 0;
  background-color: #595f70;
  color: #F1F1F1;
}

.container .all-product .product-box {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 530px;
  margin: 100px 0;
  cursor: pointer;
  transition: 1s;

}

.container .all-product .product-box-change {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 420px;
  margin: 30px;
  transition: 1s;

}

.container .all-product .product-box .left-side {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  align-items: flex-start;
  height: 100%;
  padding: 0 10px;
  transition: .5s;


}

/* for javaScript */
.container .all-product .product-box .left-right-side {
  align-items: flex-end;
  order: 3;
  transition: .5s;

}

.container .all-product .product-box .left-side .name-category {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: .5s;

}

.container .all-product .product-box .left-side .name-category-change {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  z-index: 7;
  transition: .5s;

}



/* for javaScript */
.container .all-product .product-box .left-side .name-category-right {
  align-items: flex-end;
}

.container .all-product .product-box .left-side .name-category .name {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.87);
  font-weight: 300;
  font-family: 'Times New Roman', Times, serif;
  font-size: 60px;
  margin: 0;
}

.container .all-product .product-box .left-side .name-category .category {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 300;
  color: rgba(51, 51, 51, 0.87);
  text-transform: uppercase;
}

.container .all-product .product-box .left-side button {
  box-sizing: border-box;
  border: 1px rgba(85, 85, 85, 0.534) solid;

}

.container .all-product .product-box .left-side button {
  box-sizing: border-box;
  border: 1px rgba(85, 85, 85, 0.534) solid;
  padding: 15px 50px;
  color: rgba(51, 51, 51, 0.87);
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  background-color: transparent;
  transition: var(--transition);
}

.container .all-product .product-box .left-side button:hover {
  border: transparent 1px solid;
  background-color: #595f70;
  color: #f2f2f2;
}

.container .all-product .product-box .right-side {
  box-sizing: border-box;
  width: 40%;
  height: 100%;
  order: 2;
}

.container .all-product .product-box .right-side img {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .all-product .product-box .right-side-change {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  order: 2;
  z-index: 6;
  position: absolute;
  opacity: 0;
  transition: var(--transition);
}

.container .all-product .product-box:hover .right-side-change {
  opacity: 1;
}

.container .all-product .product-box .in-center {
  box-sizing: border-box;
  position: absolute;
  z-index: 4;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 5px;
  width: 25%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.container .all-product .product-box .in-center img {
  box-sizing: border-box;
  width: 170px;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 10px;
}

.container .all-product .product-box .in-center-change img {
  transform: translateY(-30px);
}

.container .all-product .change-display {
  box-sizing: border-box;
  position: fixed;
  top: 90%;
  background-color: #fff;
  width: 200px;
  height: 50px;
  padding: 5px 0;
  border-radius: 25px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.137);
}

.container .all-product .change-display .to-column, .container .all-product .change-display .to-row {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #595f70;
  transition: .4s;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 5px;
}

.container .all-product .change-display .to-column:hover, .container .all-product .change-display .to-row:hover {
  background-color: #595f70;
  color: #fff;
}

.container .all-product .change-display .to-true {
  background-color: #595f70;
  color: #fff;
}

.container .all-product .noItems {
  width: 100%;
  height: 300px;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #595f70;
  font-family: 'Cinzel';
  font-weight: 200;
}



/*-------------------------------------------------- Products-end --------------------------------------------------*/
/*-------------------------------------------------- boutique-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- Deatils-start --------------------------------------------------*/
/*-------------------------------------------------- intro-start --------------------------------------------------*/

.container .details-page .details-page {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container .details-page .in-deails {
  box-sizing: border-box;
  width: 100%;
  height: 753px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.container .details-page .in-deails .img-side {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
}

.container .details-page .in-deails .img-side img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .details-page .in-deails .details-side {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #222;
  padding-top: 150px;
  text-align: center;

}

.container .details-page .in-deails .details-side h1 {
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 400;
  font-family: "Cinzel";
  margin-bottom: 40px;
  margin-top: 0;
}

.container .details-page .in-deails .details-side h3 {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
}

.container .details-page .in-deails .details-side p {
  box-sizing: border-box;
  width: 40%;
  font-size: 17px;
  font-style: italic;
  margin: 10px 0;
  font-family: 'Courier New', Courier, monospace;
  letter-spacing: 0px;
}

.container .details-page .in-deails .details-side button {
  box-sizing: border-box;
  padding: 15px 70px;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 60px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  transition: var(--transition);
}

.container .details-page .in-deails .details-side button:hover {
  background-color: #222;
  color: #f2f2f2;
}

.container .details-page .in-deails .img-center {
  box-sizing: border-box;
  position: fixed;
  width: 18vw;
  height: 18vw;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.863);
  padding: 4vw 5vw;
}

.container .details-page .in-deails .img-center img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;

}


/*-------------------------------------------------- intro-end --------------------------------------------------*/
/*-------------------------------------------------- more-Products-start --------------------------------------------------*/

.container .details-page .more-product-1 {
  position: relative;
  box-sizing: border-box;
  height: 753px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 4;

}

.container .details-page .left-fitt {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f2f2f2;

}

.container .details-page .left-fitt h2 {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.692);
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 auto;

}

.container .details-page .left-fitt h1 {
  box-sizing: border-box;
  font-size: 70px;
  color: #222;
  margin: 80px auto;
  font-family: "Cinzel";
  font-weight: 300;

}

.container .details-page .left-fitt button {
  box-sizing: border-box;
  border: 1px rgba(85, 85, 85, 0.212) solid;
  padding: 15px 45px;
  color: #222;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  background-color: transparent;
  transition: var(--transition);
  margin: 0 auto;

}

.container .details-page .left-fitt button:hover {
  border: transparent 1px solid;
  background-color: #222;
  color: #f2f2f2;
}

.container .details-page .right-fitt {
  box-sizing: border-box;
  background-color: #dcdde0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .details-page .right-fitt .img-box {
  box-sizing: border-box;
  width: 60%;
  height: 50%;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  left: 10%;
}

.container .details-page .right-fitt .img-box .img1 {
  box-sizing: border-box;
  width: 65%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .details-page .right-fitt .img-box .img2 {
  box-sizing: border-box;
  position: relative;
  left: -50px;
  z-index: 1;
  width: 10vw;
  height: 13.5vw;
  background-color: #fff;
  padding: 20px 15px;
  -o-object-fit: cover;
  object-fit: cover;

}

.container .details-page .more-product-2 {
  box-sizing: border-box;
  z-index: 4;
  background-color: #eaecf1;
  position: relative;
  width: 100%;
  height: 850px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}

.container .details-page .more-product-2 h1 {
  position: absolute;
  top: 7%;
  box-sizing: border-box;
  font-size: 70px;
  color: rgba(34, 34, 34, 0.534);
  font-family: "Cinzel";
  font-weight: 300;
  margin: 0;
}

.container .details-page .more-product-2 .img-box-0 {
  box-sizing: border-box;
  width: 20%;
  height: 52%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transform-style: preserve-3d;
  box-shadow: 0 10px 20px rgba(34, 34, 34, 0.158);
}

.container .details-page .more-product-2 .img-box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}

.container .details-page .more-product-2 .img-box .img1 {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .details-page .more-product-2 .img-box .img2 {
  box-sizing: border-box;
  position: absolute;
  width: 55%;
  height: 50%;
  padding: 40px 25px;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: rgba(255, 255, 255, 0.74);
  box-shadow: 0 0 10px rgba(34, 34, 34, 0.336);
}

/*-------------------------------------------------- more-Products-end --------------------------------------------------*/
/*-------------------------------------------------- Deatils-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- about-start --------------------------------------------------*/

.container .about-page {
  box-sizing: border-box;
  background-color: #faebe3;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 3;
}

.container .about-page .one-latter {
  box-sizing: border-box;
  width: 100%;
  height: 753px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Cinzel";

}

.container .about-page .one-latter h1 {
  box-sizing: border-box;
  font-size: 280px;
  color: #595f70;
  font-weight: 400;
  margin: 100px 0 0;
  text-transform: uppercase;

}

.container .about-page .one-latter h4 {
  box-sizing: border-box;
  color: #595f70;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 20px;
  text-transform: uppercase;
}

.container .about-page .beginnings {
  box-sizing: border-box;
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container .about-page .beginnings h1 {
  box-sizing: border-box;
  font-size: 50px;
  font-weight: 500;
  font-family: "Cinzel";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 100px;
  color: #595f70;
}

.container .about-page .beginnings .in-img {
  box-sizing: border-box;
  background: url(./images/about5.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 60%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-blend-mode: ; */
}

.container .about-page .beginnings .in-img p {
  box-sizing: border-box;
  font-size: 1.7vw;
  background: #595f70bd;
  width: 100%;
  height: 100%;
  color: #faebe3d5;
  padding: 30px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Cinzel";

}

.container .about-page .about-us, .container .about-page .about-us .top-fitt {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .about-page .about-us .top-fitt h1 {
  box-sizing: border-box;
  color: #595f70;
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 50px 0;
  text-align: center;
  font-family: "Cinzel";

}

.container .about-page .about-us .top-fitt p {
  box-sizing: border-box;
  color: #595f70;
  font-size: 17px;
  width: 50%;
  font-weight: 400;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container .about-page .about-us .bottom-fitt {
  box-sizing: border-box;
  width: 100%;
  height: 922px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container .about-page .about-us .bottom-fitt .old-img {
  box-sizing: border-box;
  width: 40%;
  height: 450px;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0;
}

.container .about-page .about-us .bottom-fitt h4 {
  box-sizing: border-box;
  margin-top: 10px;
  color: #595f70cc;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 200px;

}

.container .about-page .about-us .bottom-fitt .logo {
  box-sizing: border-box;
  width: 65px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: contrast(30%);
  filter: contrast(30%);
}

/*-------------------------------------------------- about-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- contact-start --------------------------------------------------*/

.container .contact-page {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #595f70;
  color: #faebe3;
  align-items: center;
}

.container .contact-page .msg-background {
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
  transform-style: preserve-3d;
  perspective: 500px;

}

.container .contact-page .display-msg-background {
  z-index: 2;
  background-color: rgba(34, 34, 34, 0.582);
}

.container .contact-page .msg-background .msg-box {
  margin: 10px auto;
  width: 40vw;
  height: 350px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #faebe3;
  color: #222;
  flex-direction: column;
  align-items: center;
  transform: scale(0) rotateX(90deg);
  transition: .8s .2s ease-out;
}

.container .contact-page .display-msg-background .msg-box {
  transform: scale(1) rotateX(0deg);
}

.container .contact-page .msg-background .msg-box h1 {
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contact-page .msg-background .msg-box h2 {
  text-align: center;
  font-size: 28px;
  width: 75%;
  text-align: center;
}

.container .contact-page .intro-contact {
  box-sizing: border-box;
  width: 100%;
  height: 753px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contact-page .intro-contact h1 {
  box-sizing: border-box;
  font-size: 14vw;
  font-family: "Cinzel";
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  transform: translateY(50px);
}

.container .contact-page .intro-contact h1 div {
  display: inline-block;
}

.container .contact-page .body-contact {
  box-sizing: border-box;
  width: 100%;
  display: flex;

  flex-direction: row;
  flex-wrap: nowrap;
  margin: 100px 0;
}

.container .contact-page .body-contact .left-side-contact, .container .contact-page .body-contact .right-side-contact {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.container .contact-page .body-contact .left-side-contact input {
  box-sizing: border-box;
  width: 80%;
  height: 35px;
  border: none;
  border-bottom: 2px #faebe3 solid;
  color: #faebe3;
  font-size: 20px;
  padding: 5px;
  background: transparent;
  margin: 20px 0;
}

.container .contact-page .body-contact .left-side-contact input::-webkit-input-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact input::-moz-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact input:-ms-input-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact input::-ms-input-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact input::placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact textarea {
  box-sizing: border-box;
  width: 80%;
  color: #faebe3;
  font-size: 20px;
  border: none;
  border-bottom: 2px #faebe3 solid;
  padding: 5px;
  background: transparent;
  margin: 20px 0;

}

.container .contact-page .body-contact .left-side-contact textarea::-webkit-input-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact textarea::-moz-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact textarea:-ms-input-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact textarea::-ms-input-placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact textarea::placeholder {
  color: #faebe37c;
}

.container .contact-page .body-contact .left-side-contact .btn-submit-form {
  box-sizing: border-box;
  font-size: 18px;
  background: #faebe3;
  color: #595f70;
  border: 3px transparent solid;
  transition: var(--transition);
  width: 250px;
  margin-top: 100px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.container .contact-page .body-contact .left-side-contact p {
  display: flex;
  align-items: center;
  color: #ff567a;
  margin: 0;
  padding: 0;
  transform: translateY(-10px);
  font-size: 14px;
  box-sizing: border-box;
  text-align: left;
  width: 80%;
}

.container .contact-page .body-contact .left-side-contact .btn-submit-form:hover {
  border: 3px #faebe3 solid;
  background: transparent;
  color: #faebe3;
}

.container .contact-page .body-contact .right-side-contact p {
  box-sizing: border-box;
  width: 60%;
  font-size: 22px;
  color: #faebe3;
  font-weight: 25px;
  font-family: "Cinzel";
}

.container .contact-page .body-contact .right-side-contact img {
  box-sizing: border-box;
  width: 50%;
  height: 400px;
  margin-top: 150px;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .contact-page .body-contact .right-side-contact h4 {
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
}


/*-------------------------------------------------- contact-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- histoire-start --------------------------------------------------*/

.container .histoire-page, .container .histoire-page .histoire-content-top, .container .histoire-page .histoire-content-bottom {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  color: rgb(65, 68, 78);
  text-align: center;
}

.container .histoire-page .histoire-content-top {
  padding: 150px 0;
  justify-content: center;
  align-items: center;
}

.container .histoire-page .histoire-content-top h1 {
  box-sizing: border-box;
  font-family: "Poppins";
  font-size: 100px;
  font-weight: 100;
  text-transform: uppercase;
  width: 50%;
  margin: 0;
}

.container .histoire-page .histoire-content-top h2 {
  box-sizing: border-box;
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0px 0 30px;
  width: 50%;
}

.container .histoire-page .histoire-content-top h4 {
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 400;
  margin: 50px 0;
  width: 50%;

}

.container .histoire-page .histoire-content-top p, .container .histoire-page .histoire-content-bottom p {
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 300;
  margin: 50px 0;
  width: 50%;

}

.container .histoire-page .histoire-content-top img {
  box-sizing: border-box;
  width: 80%;
  height: 500px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 100px;
}

.container .histoire-page .histoire-content-bottom {
  justify-content: center;
  align-items: center;
  padding-bottom: 150px;
}

.container .histoire-page .histoire-content-bottom img {
  box-sizing: border-box;
  width: 40%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 300px;

}

.container .histoire-page .histoire-content-bottom h4 {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 5px;
}

/*-------------------------------------------------- histoire-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- gallery-start --------------------------------------------------*/

.container .gallery-page {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;

}

.container .gallery-page .gallery-face {
  position: relative;
  width: 100%;
  height: 950px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.container .gallery-page .gallery-face h1 {
  position: absolute;
  box-sizing: border-box;
  font-size: 10vw;
  font-family: "Cinzel";
  font-weight: 400;
  text-align: center;
  z-index: 2;
  color: #59705d;
  transform: translateY(-150px);
  overflow: hidden;
}

.container .gallery-page .gallery-face h1 .h1-animation-before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  inset: 0;

}

.container .gallery-page .gallery-face h1 .h1-animation-after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #59705d;
  inset: 0;
}

.container .gallery-page .gallery-face img {
  box-sizing: border-box;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  width: 35%;
  height: 700px;
  transform: translateY(200px);
}

.container .gallery-page .gallery-story {
  box-sizing: border-box;
  width: 100%;
  height: 850px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #59705d;
}

.container .gallery-page .gallery-story img {
  box-sizing: border-box;
  width: 17%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .gallery-page .gallery-story .text {
  position: relative;
  box-sizing: border-box;
  width: 45%;
  padding-left: 100px;
  height: 100%;
}

.container .gallery-page .gallery-story p {
  position: relative;
  box-sizing: border-box;
  font-size: 25px;
  font-family: "moncef";
  font-weight: 500;
  color: #faebe3;
  top: 30%;
  font-style: italic;

}

.container .gallery-page .gallery-story a {
  position: relative;
  box-sizing: border-box;
  top: 35%;
  color: #faebe3;
  font-weight: 300;
}

.container .gallery-page .All-picture {
  box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 200px 0;
  transition: 2s;
  position: relative;
  overflow: hidden;
}

.container .gallery-page .All-picture .chnage-position {
  position: absolute;
  inset: 0;
  top: 100px;
  width: 100px;
  max-height: 80px;
  font-size: 70px;
  margin: 0 auto;
  text-align: center;
  color: #59705d;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s;

}

.container .gallery-page .All-picture .chnage-position .diamond-logo {
  transition: .7s;

}

.container .gallery-page .All-picture .chnage-position .diamond-logo:hover {
  transform: scale(1.1);
}

.container .gallery-page .img-box-left {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 50px 0;
  transform: translateX();
  display: flex;
  justify-content: flex-start;
}

.container .gallery-page .img-box-right {
  display: flex;
  justify-content: flex-end;
}

.container .gallery-page .img-box-left img {
  box-sizing: border-box;
  position: relative;
  inset: 0;
  width: 400px;
}

/*-------------------------------------------------- gallery-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- cart-start --------------------------------------------------*/

.container .cart-page {
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0%;
  background-color: #fff;
  justify-content: center;
  padding: 0 10px;
  overflow: hidden;
}

.container .cart-page .pay-fill {
  width: 43%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 120px 5vw 120px 0;
  overflow: hidden;

}

.container .cart-page .pay-fill .title {
  font-weight: 400;
  font-size: 25px;
  margin: 10px 0;
}

.container .cart-page .pay-fill .payment-box {
  width: 100%;
  overflow: hidden;
  margin: 20px 0;
  border: 1px #ddd solid;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.container .cart-page .pay-fill .payment-box legend {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin: 0%;
  color: #333;
  padding: 0 1vw;
}

.container .cart-page .pay-fill .payment-box .box {
  width: 30%;
  margin: 0 1.5%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
}

.container .cart-page .pay-fill .payment-box .box img {
  width: 5.5vw;
  height: 1.4vw;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .cart-page .pay-fill .payment-box .box1 {
  background-color: #5c33f4;
}

.container .cart-page .pay-fill .payment-box .box1:hover {
  background-color: #3700fc;
}

.container .cart-page .pay-fill .payment-box .box2 {
  background-color: #ffc71d;
}

.container .cart-page .pay-fill .payment-box .box2:hover {
  background-color: #ebb000;
}

.container .cart-page .pay-fill .payment-box .box3 {
  background-color: #222;
}

.container .cart-page .pay-fill .payment-box .box3:hover {
  background-color: rgb(0, 0, 0);
}

.container .cart-page .pay-fill .payment-box .box3 img {
  width: 3.2vw;
  height: 1.2vw;
}

.container .cart-page .pay-fill .payment-box .box4 {
  background-color: rgb(192, 252, 252);
}

.container .cart-page .pay-fill .payment-box .box4:hover {
  background-color: rgb(155, 255, 255);
}

.container .cart-page .pay-fill h4 {
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
  color: #999;
}

.container .cart-page .pay-fill .contact-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
}

.container .cart-page .pay-fill .contact-info .title {
  font-weight: 400;
  font-size: 25px;
  margin: 10px 0;
}

.container .cart-page .pay-fill .contact-info form {
  width: 100%;
  height: auto;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}

.container .cart-page .pay-fill .contact-info form .input {
  width: 100%;
  height: 50px;
  font-size: 16px;
  padding: 5px 10px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px 0;
  transition: .3s;
}

.container .cart-page .pay-fill .contact-info form .input:focus {
  border: #7f3d1c solid 3px;
}

.container .cart-page .pay-fill .contact-info form .btn {
  border: none;
  width: 200px;
  height: 50px;
  margin: 20px 0 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #7f3d1c;
  cursor: pointer;
  transition: .3s;
}

.container .cart-page .pay-fill .contact-info form .btn:hover {
  background-color: #632708;

}

.container .cart-page .items-fill {
  width: 30%;
  background-color: #f7f7f7;
  padding: 120px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #ddd;
  overflow: hidden;
}

.container .cart-page .items-fill .all-products {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-bottom: 1px #ddd solid;
  padding-bottom: 10px;
  overflow: hidden;
}

.container .cart-page .items-fill .all-products .product-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 10px 0;
  position: relative;
}

.container .cart-page .items-fill .all-products .product-box .img {
  width: 4.5vw;
  height: 4vw;
  border: 1px #ddd solid;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
}

.container .cart-page .items-fill .all-products .product-box .img .current {
  position: absolute;
  inset: 0;
  left: 86%;
  top: -15%;
  width: 1.3vw;
  height: 1.3vw;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: rgba(34, 34, 34, 0.575);
  z-index: 1;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container .cart-page .items-fill .all-products .product-box .img img {
  width: 80%;
  height: 80%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .cart-page .items-fill .all-products .product-box h3 {
  font-size: 1vw;
  font-weight: 400;
  color: #222;
  margin: 0 5px;
}

.container .cart-page .items-fill .all-products .product-box h4 {
  font-size: 1vw;
  font-weight: 400;
  color: #222;
  font-weight: 600;
}

.container .cart-page .items-fill .all-products .product-box h5 {
  position: absolute;
  margin-left: 16vw;
  padding-right: .5vw;
  border-radius: 30px;
  line-height: 16px;
  color: rgb(194, 29, 29);
  font-size: .7vw;
  inset: 0;
  top: 75%;
  text-align: right;
  cursor: pointer;
  transition: .3s;

}

.container .cart-page .items-fill .all-products .product-box h5:hover {
  color: #f3f3f3;
  background-color: rgb(145, 23, 23);

}

.container .cart-page .items-fill form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  overflow: hidden;
}

.container .cart-page .items-fill form .input {
  width: 75%;
  height: 45px;
  font-size: 1vw;
  padding: 5px 10px;
  color: #333;
  border: 2.5px solid #ddd;
  border-radius: 5px;
  transition: .3s;
}

.container .cart-page .items-fill form .input:focus {
  border: 2.5px solid #7f3d1c;

}

.container .cart-page .items-fill form .input::-webkit-input-placeholder {
  font-weight: 200;
  color: #999;
}

.container .cart-page .items-fill form .input::-moz-placeholder {
  font-weight: 200;
  color: #999;
}

.container .cart-page .items-fill form .input:-ms-input-placeholder {
  font-weight: 200;
  color: #999;
}

.container .cart-page .items-fill form .input::-ms-input-placeholder {
  font-weight: 200;
  color: #999;
}

.container .cart-page .items-fill form .input::placeholder {
  font-weight: 200;
  color: #999;
}

.container .cart-page .items-fill form .btn {
  width: 25%;
  margin-left: 5%;
  border: none;
  height: 42.5px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #bbb;
  transition: .3s;
  font-size: 1vw;
  text-transform: uppercase;
}

.container .cart-page .items-fill form .btn.btn-change {
  background-color: #7f3d1c;
  cursor: pointer;

}

.container .cart-page .items-fill form .btn.btn-change:hover {
  background-color: #632708;
}

.container .cart-page .items-fill .ttl-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  overflow: hidden;
}

.container .cart-page .items-fill .ttl-box h3 {
  font-size: 1.3vw;
  font-weight: 400;
  color: #222;
  margin-right: 5px;
}

.container .cart-page .items-fill .ttl-box h4 {
  font-size: 1.5vw;
  font-weight: bold;
  color: #222;
  margin-left: 5px;

}

/*-------------------------------------------------- cart-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */


/*-------------------------------------------------- Admin --------------------------------------------------*/

/*-------------------------------------------------- home-admin-start --------------------------------------------------*/

.container .home-page {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px;
  padding-left: 65px;
  background: var(--color-admin-light2);
}

.container .home-page .mini-title {
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font-size: 30px;
  font-weight: 500;
  color: #595f70;
  padding: 0 5%;
  padding-bottom: 50px;
}

.container .home-page .in-home-page {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.container .home-page .in-home-page .left-fitt {
  box-sizing: border-box;
  width: 30%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.container .home-page .in-home-page .left-fitt-box-order {
  box-sizing: border-box;
  width: 100%;
  height: 180px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #777;
  box-shadow: 0 10px 30px #1310781a;
  background: #fff;
  border-radius: 20px;
  margin: 5%;
}

.container .home-page .in-home-page .left-fitt-box-order h2 {
  box-sizing: border-box;
  font-size: 60px;
  font-weight: 400;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-admin-primary);


}

.container .home-page .in-home-page .left-fitt-box-order h3 {
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 400;
  margin: 15px 0;
  color: #999;
}

.container .home-page .in-home-page .left-fitt-box {
  box-sizing: border-box;
  width: 40%;
  height: 180px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #777;
  box-shadow: 0 10px 30px #1310781a;
  background: #fff;
  border-radius: 20px;
  margin: 5%;

}

.container .home-page .in-home-page .left-fitt-box h2 {
  box-sizing: border-box;
  font-size: 50px;
  font-weight: 400;
  margin: 15px 0;
  display: flex;
  align-items: center;
  color: var(--color-admin-primary);
}

.container .home-page .in-home-page .left-fitt-box h3 {
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 400;
  margin: 15px 0;
  color: #999;
}

.container .home-page .in-home-page .left-fitt-big-box {
  box-sizing: border-box;
  width: 100%;
  height: 250px;
  display: flex;
  border-radius: 20px;
  flex-direction: row;
  padding: 2.5% 1.5%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 30px #1310781a;
  margin: 5%;
  background: #fff;
}

.container .home-page .in-home-page .left-fitt-big-box .add-box {
  box-sizing: border-box;
  border-radius: 20px;
  width: 40%;
  margin: 2% 5%;
  height: 40%;
  box-shadow: 0px 0 10px #36837e23;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 15px;
  cursor: pointer;
  transition: .5s;
}

.container .home-page .in-home-page .left-fitt-big-box .add-box:hover {
  background-color: var(--color-admin-primary);
  color: #ffff;
}

.container .home-page .in-home-page .left-fitt-big-box .add-box h2 {
  box-sizing: border-box;
  font-size: 30px;
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--color-admin-primary);
  transition: .5s;
  transform: rotate(180deg);

}

.container .home-page .in-home-page .left-fitt-big-box .add-box:hover h2 {
  color: #ffff;
}

.container .home-page .in-home-page .left-fitt-big-box .add-box:hover {
  box-shadow: 0px 0 20px #13107828;
}

.container .home-page .in-home-page .left-fitt-big-box .add-box:hover h2 {
  transform: rotate(90deg);
}

.container .home-page .in-home-page .left-fitt-big-box .add-box h3 .logo {
  margin: 0 5px;
  color: var(--color-admin-light3);
}

.container .home-page .in-home-page .left-fitt-big-box .add-box h3 {
  box-sizing: border-box;
  color: #888;
  margin: 5px 0;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s;
}

.container .home-page .in-home-page .left-fitt-big-box .add-box:hover h3 {
  color: #ffff;
}

.container .home-page .in-home-page .right-fitt {
  box-sizing: border-box;
  width: 60%;
  display: flex;
  flex-direction: column;

}

.container .home-page .in-home-page .right-fitt .intro-box,
.container .home-page .in-home-page .right-fitt .end-box {
  box-sizing: border-box;
  width: 100%;
  height: 234.5px;
  background: url(./images/jewelry-background-hd-1600x1200-56018.jpg) no-repeat center;
  background-size: cover;
  border-radius: 20px;
  color: #fff;
  overflow: hidden;
  margin: 2.5%;
}

.container .home-page .in-home-page .right-fitt .end-box {
  background: url(./images/beautiful-golden-jewelry-with-jem-red-velvet-background_99074-47.jpg) no-repeat center;
  background-size: cover;

}

.container .home-page .in-home-page .right-fitt .intro-box .in-intro-box,
.container .home-page .in-home-page .right-fitt .end-box .in-intro-box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #272864b7;
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.container .home-page .in-home-page .right-fitt .end-box .in-intro-box {
  background: #272864b7;
}

.container .home-page .in-home-page .right-fitt .intro-box .in-intro-box h2,
.container .home-page .in-home-page .right-fitt .end-box .in-intro-box h2 {
  box-sizing: border-box;
  margin: 10px 0;
  font-weight: 400;
  font-size: 28px;
  color: #fff;
  width: 60%;
  display: flex;
}

.container .home-page .in-home-page .right-fitt .intro-box .in-intro-box button,
.container .home-page .in-home-page .right-fitt .end-box .in-intro-box button {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 50px;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0;
  text-transform: uppercase;
  border: 2px solid transparent;
  background: #fff;
  color: var(--color-admin-primary);
  cursor: pointer;
  border: 2px solid transparent;
  transition: var(--transition);
}

.container .home-page .in-home-page .right-fitt .intro-box .in-intro-box button:hover,
.container .home-page .in-home-page .right-fitt .end-box .in-intro-box button:hover {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.container .home-page .in-home-page .right-fitt .all-manage-box {
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: .29% 2.5%;
  transform: scale(1.03);

}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box {
  box-sizing: border-box;
  width: 100%;
  margin: 2.5% 1.5%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  box-shadow: 0 10px 30px #36837e36;
  border-radius: 20px;
  height: 90%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  color: #c1c2ff;
  transition: .5s;
}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box.category {
  background-image: url(./images/jewelry-wallpaper-hd-1440x960-55972.jpg);

}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box::after {
  background: #272864b7;
  content: "";
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 1;
  position: absolute;
  transition: .5s;
}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box:hover::after {
  background: transparent;
}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box.admin {
  background-image: url(./images/jewelry-wallpaper-hd-2560x1440-56045.jpg);
}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box.bijoux {
  background-image: url(./images/jewelry-background-hd-2560x1600-56002.jpg);
}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box.gallery {
  background-image: url(./images/golden-modern-bracelets-pastel-colors-pink-blue-white-paper-background_112112-981.jpg);

}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box h2 {
  box-sizing: border-box;
  margin: 5px 0;
  font-weight: 400;
  z-index: 2;
}

.container .home-page .in-home-page .right-fitt .all-manage-box .manage-box h3 {
  box-sizing: border-box;
  margin: 5px 0;
  font-size: 12px;
  font-weight: 400;
  z-index: 2;
  opacity: .8;
}


/*-------------------------------------------------- home-admin-end --------------------------------------------------*/

/*-------------------------------------------------- navbar-start --------------------------------------------------*/

.container .navbar-admin {
  box-sizing: border-box;
  height: 100%;
  max-width: 300px;
  overflow: hidden;
  display: flex;
  margin: 0;
  flex-direction: column;
  position: fixed;
  background-color: var(--color-admin-primary);
  color: var(--color-admin-light2);
  padding: 30px 0;
  z-index: 10;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.container .navbar-admin .box-content {
  height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
  transition: .5s;
  border-left: 4px solid transparent;
  border-radius: 3px;
  transition: var(--transition);
  cursor: pointer;
}

.container .navbar-admin .box-content.active {
  border-left: 4px solid var(--color-admin-light2);
}

.container .navbar-admin .box-content .left-side {
  box-sizing: border-box;
  width: 65px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-admin-light3);
  font-size: 22px;
  padding-left: 15px;
  transition: .7s;


}

.container .navbar-admin .box-content .right-side {
  box-sizing: border-box;
  width: 0px;
  color: var(--color-admin-light3);
  display: flex;
  font-size: 17px;
  align-items: center;
  transition: .5s;
}

.container .navbar-admin .box-content .right-side h1 {
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  margin-left: 30px;
}

.container .navbar-admin:hover .box-content .right-side {
  width: 200px;
}

.container .navbar-admin .box-content:hover .right-side {
  color: #f2f2f2;
}

.container .navbar-admin .box-content:hover .left-side {
  transform: scale(1.3);
  color: #f2f2f2;
}

/*-------------------------------------------------- navbar-end --------------------------------------------------*/
/*-------------------------------------------------- admin-manage-start --------------------------------------------------*/

.container .admin-panel {
  width: 100%;
  background: var(--color-admin-light2);
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 800px;
  margin-left: 32.5px;
  padding-bottom: 40px;
}

.container .admin-panel .confirm-page {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.603);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .admin-panel .confirm-page.confirm-page-hidden {
  display: none;
}

.container .admin-panel .confirm-page .confirm-box {
  width: 40vw;
  height: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  flex-direction: column;
  border-radius: 30px;
  flex-wrap: nowrap;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.container .admin-panel .confirm-page .confirm-box h3 {
  font-size: 3vw;
  font-weight: 400;
  margin: 30px 10px;
  color: #333;
}

.container .admin-panel .confirm-page .confirm-box .confirm-buttons {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 4vw;
  margin-top: 10px;
}

.container .admin-panel .confirm-page .confirm-box .confirm-buttons .cancel,
.container .admin-panel .confirm-page .confirm-box .confirm-buttons .confirm {
  cursor: pointer;
  transition: .3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .admin-panel .confirm-page .confirm-box .confirm-buttons .confirm {
  color: #34a030;
}

.container .admin-panel .confirm-page .confirm-box .confirm-buttons .cancel {
  color: #c70a00;
}

.container .admin-panel .confirm-page .confirm-box .confirm-buttons .confirm:hover {
  color: #146611;
}

.container .admin-panel .confirm-page .confirm-box .confirm-buttons .cancel:hover {
  color: #ff0000;
}













.container .admin-panel .page-order {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 26, 49, 0.582);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

}

.container .admin-panel .page-order.page-order-hidden {
  display: none;
}

.container .admin-panel .page-order .confirm-box {
  width: 40vw;
  height: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  flex-direction: column;
  border-radius: 30px;
  flex-wrap: nowrap;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  overflow-y: auto;
}



.container .admin-panel .page-order .confirm-box h3 {
  font-size: 2vw;
  font-weight: 600;
  margin: 10px;
  color: #333;
  text-align: center;
  width: 80%;
  padding-bottom: 20px;
  border-bottom: 2px solid #ddd;
  text-transform: uppercase;
}

.container .admin-panel .page-order .confirm-box .h4 {
  font-size: 1.1vw;
  font-weight: 400;
  margin: 20px 10px;
  color: #555;
  width: 80%;
}

.container .admin-panel .page-order .confirm-box .h4 h4 {
  font-size: 1.4vw;
  font-weight: 600;
  color: #333;
}






















.container .admin-panel .header-admin-panel {
  box-sizing: border-box;
  width: 90%;
  height: 350px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 40px auto;
}

.container .admin-panel .header-admin-panel .left-side {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 40px 50px;
  margin-right: 20px;
  box-shadow: var(--primary-shadow);
}

.container .admin-panel .header-admin-panel .left-side.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.container .admin-panel .header-admin-panel .left-side h1 {
  box-sizing: border-box;
  font-size: 100px;
  font-weight: 400;
  text-transform: uppercase;
  color: #595f70;
  margin: 0 0 10px;
}

.container .admin-panel .header-admin-panel .left-side.contact h1 {
  font-size: 130px;
}

.container .admin-panel .header-admin-panel .left-side .add-item {
  box-sizing: border-box;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: var(--color-admin-primary);
  border-radius: 15px;
  color: #f2f2f2;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  border: 2px transparent solid;
  transition: var(--transition);
}

.container .admin-panel .header-admin-panel .left-side .add-item:hover {
  color: var(--color-admin-primary);
  border: solid var(--color-admin-primary) 2px;
  background-color: transparent;
}

.container .admin-panel .header-admin-panel .left-side .add-item h2:first-child {
  padding-top: 4px;
}

.container .admin-panel .header-admin-panel .left-side .add-item h2 {
  box-sizing: border-box;
  margin: auto 5px;
  font-size: 20px;
  font-weight: 300;

}

.container .admin-panel .header-admin-panel .right-side {
  box-sizing: border-box;
  flex-direction: column;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(135deg, #f2f2f2, var(--color-admin-dark));
  border-radius: 15px;
  width: 40%;
  margin-left: 20px;
  box-shadow: var(--primary-shadow);
}

.container .admin-panel .header-admin-panel .right-side h1 {
  box-sizing: border-box;
  font-size: 170px;
  color: var(--color-admin-light2);
  margin: 0 auto;
  display: flex;
}

.container .admin-panel .header-admin-panel .right-side h2 {
  box-sizing: border-box;
  font-size: 20px;
  margin: 10px auto;
  color: var(--color-admin-light2);

  font-weight: 500;
}

.container .admin-panel table {
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  margin: 0 auto;
  border-radius: 15px;
  padding: 3%;
  box-shadow: var(--primary-shadow);

}

.container .admin-panel table tr {
  box-sizing: border-box;
  border: red solid;
  transition: .7s;
}

/* .container .admin-panel table tr:hover{
  transform: scale(1.1);
  box-shadow: 0 0 30px rgba(34, 34, 34, 0.199);

} */
.container .admin-panel table tr.cd td {
  background-color: #dcdde9;
}

.container .admin-panel table tr td {
  box-sizing: border-box;
  height: 55px;
  text-align: center;
  background-color: #f1f1f1;
  color: #595f70e5;
}

.container .admin-panel table tr td .update {
  color: #34a030;
}

.container .admin-panel table tr td .delete {
  color: #f0485e;
}

.container .admin-panel table tr td .update, .container .admin-panel table tr td .delete {
  box-sizing: border-box;
  font-size: 25px;
  margin: 0 15px;
  transition: .5s;
}

.container .admin-panel table tr td .update .update-in, .container .admin-panel table tr td .delete .delete-in {
  transition: .5s;
}

.container .admin-panel table tr td .update:hover .update-in {
  color: #1c6d1a;
  transform: scale(1.2);
}

.container .admin-panel table tr td .delete:hover .delete-in {
  color: #852532;
  transform: scale(1.2);

}

.container .admin-panel table tr td.img {
  min-height: 55px;
  padding: 8px 0 3px;
  overflow: hidden;
}

.container .admin-panel table tr td img {
  width: 50px;
  height: 50px;
  border: 3px #595f70 solid;
  border-radius: 100%;
  padding: 2px;
  -o-object-fit: cover;
  object-fit: cover;
  transition: .5s;
  cursor: pointer;

}

.container .admin-panel table tr td img:hover {
  transform: scale(1.2);
}

.container .admin-panel table tr td .zoomImg {
  position: fixed;
  inset: 0;
  border-radius: 20px;
  width: 40%;
  height: 80%;
  margin: auto auto;
}

.container .admin-panel table tr td .zoomImg:hover {
  transform: scale(1);
}

.container .admin-panel table tr td .active-yes {
  font-size: 25px;
  color: #34a030;
}

.container .admin-panel table tr td .active-no {
  font-size: 25px;
  color: #f0485e;
}

.container .admin-panel table tr td .love-logo {
  color: #ff3d57;
}

.container .admin-panel table tr th {
  box-sizing: border-box;
  height: 55px;
  text-align: center;
  background-color: var(--color-admin-dark);
  color: #fff;
  font-weight: 500;
}

/*-------------------------------------------------- add-admin-start --------------------------------------------------*/

.container .add-admin-page {
  box-sizing: border-box;
  width: 100%;
  min-height: 750px;
  background: var(--color-admin-light2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32.5px;
  padding: 100px 0;
}

.container .add-admin-page .add-msg {
  position: absolute;
  top: -30%;
  background-color: #fff;
  border-radius: 15px;
  width: 300px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  transition: .5s ease-in-out;
  z-index: 20;
  padding: 5px;
  box-shadow: 0 0 20Px var(--primary-shadow);
}

.container .add-admin-page .add-msg.inshowing {
  top: 3%;
}

.container .add-admin-page .add-msg h1 {
  box-sizing: border-box;
  color: rgb(253, 92, 92);
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase;
  margin: 5px 0;
}

.container .add-admin-page .add-msg h2 {
  box-sizing: border-box;
  color: rgb(255, 108, 108);
  font-weight: 500;
  font-size: 23px;
  text-transform: uppercase;
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

}

.container .add-admin-page .in-add-admin {
  box-sizing: border-box;
  width: 75%;
  background-color: #fff;
  border-radius: 15px;
  padding: 50px;
  display: flex;
  flex-direction: row;
}

.container .add-admin-page .in-add-admin .left-side {
  box-sizing: border-box;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.container .add-admin-page .in-add-admin .left-side img {
  box-sizing: border-box;
  width: 70%;
  -o-object-fit: cover;
  object-fit: cover;
}

.container .add-admin-page .in-add-admin .right-side {
  box-sizing: border-box;
  padding: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container .add-admin-page .in-add-admin .right-side h2 .aroow-right {
  font-size: 16px;
  box-sizing: border-box;
  transform: translateY(3px);
  transition: .5s;
}

.container .add-admin-page .in-add-admin .right-side a:hover .aroow-right {
  transform: translate(20px, 3px);
}

.container .add-admin-page .in-add-admin .right-side a {
  margin: 60px auto 0;

}

.container .add-admin-page .in-add-admin .right-side h2 {
  font-size: 15px;
  width: 100%;
  color: rgba(153, 153, 153, 0.719);
  justify-content: center;
  align-items: center;
  font-weight: 200;
  text-align: center;
  box-sizing: border-box;
}

.container .add-admin-page .in-add-admin .right-side form {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.container .add-admin-page .in-add-admin .right-side form input,
.container .add-admin-page .in-add-admin .right-side form textarea,
.container .add-admin-page .in-add-admin .right-side form select {
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgba(153, 153, 153, 0.747);
  font-size: 20px;
  font-weight: 300;
  width: 100%;
  margin: 15px 0;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  color: #555;
  transition: var(--transition);
  border-top: 2px solid transparent;
}

.container .add-admin-page .in-add-admin .right-side form select option {
  background-color: #eee;
  color: #777;
}

.container .add-admin-page .in-add-admin .right-side form select:focus {
  outline: none;
}

.container .add-admin-page .in-add-admin .right-side form textarea::-webkit-input-placeholder {
  color: var(--color-admin-light3);
}

.container .add-admin-page .in-add-admin .right-side form textarea::-moz-placeholder {
  color: var(--color-admin-light3);
}

.container .add-admin-page .in-add-admin .right-side form textarea:-ms-input-placeholder {
  color: var(--color-admin-light3);
}

.container .add-admin-page .in-add-admin .right-side form textarea::-ms-input-placeholder {
  color: var(--color-admin-light3);
}

.container .add-admin-page .in-add-admin .right-side form textarea::placeholder {
  color: var(--color-admin-light3);
}

.container .add-admin-page .in-add-admin .right-side form .radio-box {
  width: 100%;
  margin: 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid rgba(153, 153, 153, 0.747);
  padding: 10px;
  color: var(--color-admin-light3);
  box-sizing: border-box;
}

.container .add-admin-page .in-add-admin .right-side form .radio-box h3 {
  margin: 0 20px 0 0;
  font-weight: 400;
  color: rgba(153, 153, 153, 0.747);
}

.container .add-admin-page .in-add-admin .right-side form .radio-box .radio-in {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.container .add-admin-page .in-add-admin .right-side form .radio-box input {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  box-sizing: border-box;
  width: 30px;
  transform: scale(1.3);

}

.container .add-admin-page .in-add-admin .right-side form .radio-box input {
  border: solid var(--color-admin-primary);
  background-color: var(--color-admin-primary);
}




.container .add-admin-page .in-add-admin .right-side form input:focus {
  outline: red;
}

.container .add-admin-page .in-add-admin .right-side form input::-webkit-input-placeholder {
  color: rgba(153, 153, 153, 0.747);
}

.container .add-admin-page .in-add-admin .right-side form input::-moz-placeholder {
  color: rgba(153, 153, 153, 0.747);
}

.container .add-admin-page .in-add-admin .right-side form input:-ms-input-placeholder {
  color: rgba(153, 153, 153, 0.747);
}

.container .add-admin-page .in-add-admin .right-side form input::-ms-input-placeholder {
  color: rgba(153, 153, 153, 0.747);
}

.container .add-admin-page .in-add-admin .right-side form input::placeholder {
  color: rgba(153, 153, 153, 0.747);
}

.container .add-admin-page .in-add-admin .right-side form .btn-add {
  box-sizing: border-box;
  margin: 30px 0 0;
  max-width: 150px;
  padding: 8px 10px;
  font-size: 18px;
  color: #fff;
  background-color: var(--color-admin-primary);
  border: transparent solid 2px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  cursor: pointer;
}

.container .add-admin-page .in-add-admin .right-side form .btn-add:hover {
  background-color: transparent;
  color: var(--color-admin-primary);
  border: var(--color-admin-primary) solid 2px;
}

.container .add-admin-page .in-add-admin .right-side form p {
  display: flex;
  align-items: center;
  color: rgb(241, 82, 82);
  margin: 0;
  padding: 0;
  transform: translateY(-10px);
  font-size: 14px;
  box-sizing: border-box;
}

/*-------------------------------------------------- add-admin-end --------------------------------------------------*/
/*-------------------------------------------------- update-admin-start --------------------------------------------------*/

.container .add-admin-page .in-add-admin .left-side img {
  width: 55%;
}

.container .add-admin-page .in-add-admin .right-side .update-title {
  text-align: center;
  font-weight: 500;
  margin: 0;
  font-size: 40px;
  color: rgb(112, 111, 111);
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .add-admin-page .in-add-admin .right-side .update-title2 {
  position: absolute;
  top: -20px;
  left: 96%;
  color: rgb(199, 51, 76);
  cursor: pointer;
  box-sizing: border-box;
  transition: .5s;
}

.container .add-admin-page .in-add-admin .right-side .update-title2:hover {
  color: rgb(134, 33, 50);

}

.container .add-admin-page .in-add-admin .right-side .list-before {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 300px;
  min-height: 400px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.295);
  border-radius: 15px;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: .7s;
  left: 100%;
  overflow: hidden;
}

.container .add-admin-page .in-add-admin .right-side .list-before.showing-list-before {
  z-index: 0;
  opacity: 1;
  left: 78%;
}

.container .add-admin-page .in-add-admin .right-side .list-before h6 {
  color: rgb(199, 51, 76);
  position: absolute;
  font-size: 25px;
  font-weight: bold;
  left: 85%;
  transition: var(--transition);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .add-admin-page .in-add-admin .right-side .list-before h6:hover {
  color: rgb(134, 33, 50);

}

.container .add-admin-page .in-add-admin .right-side .list-before h2 {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 30px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.container .add-admin-page .in-add-admin .right-side .list-before h2 .list-items-logo {
  font-size: 50px;
  color: var(--color-admin-primary);
}

.container .add-admin-page .in-add-admin .right-side .list-before .list-before-box {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px;

}

.container .add-admin-page .in-add-admin .right-side .list-before .list-before-box h2 {
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0;
  justify-content: flex-start;
  width: 100%;
  white-space: pre-wrap;
}

.container .add-admin-page .in-add-admin .right-side .list-before .list-before-box h4 {
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0;
  width: 100%;
  white-space: pre-wrap;

}

.container .add-admin-page .in-add-admin .right-side .list-before .list-before-box img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 2px;
  border: 3px #595f70 solid;
  -o-object-fit: cover;
  object-fit: cover;
}

/*-------------------------------------------------- update-admin-end --------------------------------------------------*/
/*-------------------------------------------------- admin-manage-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- category-manage-start --------------------------------------------------*/

.container .admin-panel .header-admin-panel .left-side .category-title {
  font-size: 100px;
}

/*-------------------------------------------------- category-manage-end --------------------------------------------------*/

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

/*-------------------------------------------------- Gallery-manage-start --------------------------------------------------*/

.container .admin-panel .header-admin-panel .left-side .gallery-title {
  font-size: 100px;
}

/*-------------------------------------------------- Gallery-manage-end --------------------------------------------------*/

/*-------------------------------------------------- contact-manage-start --------------------------------------------------*/

.container .admin-panel .All-Orders {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-left: 65px;
  padding: 1% 5%;
  justify-content: space-evenly;
}

.container .admin-panel .All-Orders .order-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 300px;
  margin: 20px;
  overflow: visible;
}

.container .admin-panel .All-Orders .order-box .in-order-box {
  box-sizing: border-box;
  width: 100%;
  height: 90%;
  background: var(--color-admin-primary);
  border-radius: 40px;
  box-shadow: 0 10px 20px #120cb369;
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
}

.container .admin-panel .All-Orders .order-box .in-order-box h2.title {
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: var(--color-admin-light2);
  padding-bottom: 10px;
  border-bottom: 2px solid #e8eefb93;
  margin: 0 30px 30px;
  overflow: hidden;
}

.container .admin-panel .All-Orders .order-box .in-order-box h4 {
  box-sizing: border-box;
  font: 20px;
  font-weight: 400;
  margin: 10px;
  color: var(--color-admin-light2);
  opacity: .9;
  overflow: hidden;

}

.container .admin-panel .All-Orders .order-box p {
  box-sizing: border-box;
  color: #999;
  padding: 5px 10px;
}

/*-------------------------------------------------- contact-manage-end --------------------------------------------------*/
/*-------------------------------------------------- login-start --------------------------------------------------*/

.container .login-page {
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background: url(./images/background.jpg) center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container .login-page::after {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #27286479;
}

.container .login-page form {
  box-sizing: border-box;
  padding: 60px 50px 120px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 5px 40px #a2a5e83f;
}

.container .login-page form p {
  display: flex;
  align-items: center;
  color: rgb(241, 82, 82);
  margin: 0;
  padding: 0;
  transform: translateY(-10px);
  font-size: 14px;
  box-sizing: border-box;
}

.container .login-page form input {
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgba(153, 153, 153, 0.747);
  font-size: 20px;
  font-weight: 300;
  width: 100%;
  margin: 15px 0;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  color: rgb(226, 224, 224);
  transition: var(--transition);
  border-top: 2px solid transparent;
  background-color: transparent;
}

.container .login-page form input::-webkit-input-placeholder {
  color: #999;
}

.container .login-page form input::-moz-placeholder {
  color: #999;
}

.container .login-page form input:-ms-input-placeholder {
  color: #999;
}

.container .login-page form input::-ms-input-placeholder {
  color: #999;
}

.container .login-page form input::placeholder {
  color: #999;
}

.container .login-page form .btn-add {
  box-sizing: border-box;
  margin: 40px auto 0;
  max-width: 150px;
  padding: 8px 10px;
  font-size: 18px;
  color: #fff;
  background-color: var(--color-admin-primary);
  border: transparent solid 2px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  cursor: pointer;
}

.container .login-page form .btn-add:hover {
  background-color: transparent;
  color: var(--color-admin-primary);
  border: var(--color-admin-primary) solid 2px;
}



/*-------------------------------------------------- login-end --------------------------------------------------*/



/*-------------------------------------------------- error-start --------------------------------------------------*/
.error {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}

.error img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 25rem;
  margin-bottom: 2rem;
}

.error .error-back {
  padding: .5rem 1rem;
  border-radius: 5px;
  transition: .3s;
  cursor: pointer;
  text-transform: uppercase;
  border: #111 2px solid;
}

.error .error-back:hover {
  background-color: #111;
  color: #fff;
}

/*-------------------------------------------------- error-end --------------------------------------------------*/























/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */






































/*-------------------------------------------------- Media-Respensive-Start --------------------------------------------------*/
/*-------------------------------------------------- 10240000000 --------------------------------------------------*/

@media (max-width:1025px) {
  /*-------------------------------------------------- gallery-start --------------------------------------------------*/

  .container .gallery-page .gallery-face img {
    width: 40%;
  }

  .container .gallery-page .gallery-story img {
    width: 22%;
  }

  .container .gallery-page .gallery-story p {
    font-size: 22px;
    top: 32%;
  }

  .container .gallery-page .img-box-left img {
    width: 380px;
  }

  /*-------------------------------------------------- gallery-end --------------------------------------------------*/
  /*-------------------------------------------------- bijoux-start --------------------------------------------------*/
  .container .in-boutique .boutique-story img {
    width: 25%;
  }

  .container .in-boutique .boutique-story p {
    font-size: 20px;
    top: 3.5%;
  }

  .container .in-boutique .boutique-story a {
    top: 7%;
  }

  .container .all-product .product-box {
    height: 400px;
  }

  .container .all-product .product-box .in-center img {
    width: 150px;
    height: 180px;
  }

  .container .all-product .product-box .in-center {
    height: 40%;
  }

  /*-------------------------------------------------- bijoux-end --------------------------------------------------*/
  /*-------------------------------------------------- home-start --------------------------------------------------*/
  .content .intro-photos .to-shop-page button {
    padding: 10px 30px;
    font-size: 1.5vw;
  }

  .content .intro-photos .img1 img {
    height: 250px;
  }

  .content .intro-photos .img2 img {
    height: 400px;
  }

  .content .intro-photos .img3 img {
    height: 280px;
  }

  .content .intro-photos .img4 img {
    height: 400px;
  }

  .container .to-about button {
    padding: 5px 20px;
    font-size: 1.5vw;
  }

  .container .to-about h4 {
    font-size: 1.5vw;
  }

  /*-------------------------------------------------- home-end --------------------------------------------------*/
  /*-------------------------------------------------- details-start --------------------------------------------------*/

  .container .details-page .more-product-2 {
    height: 800px;
  }

  .container .details-page .more-product-2 .img-box-0 {
    height: 45%;
  }

  .container .details-page .right-fitt .img-box .img2 {
    width: 35%;
    height: 45%;
  }

  /*-------------------------------------------------- details-end --------------------------------------------------*/
  /*-------------------------------------------------- about-start --------------------------------------------------*/
  .container .about-page .about-us .bottom-fitt .old-img {
    height: 400px;
  }

  .container .about-page .about-us .bottom-fitt h4 {
    font-size: 10px;
  }

  /*-------------------------------------------------- about-end --------------------------------------------------*/
  /*-------------------------------------------------- cart-start --------------------------------------------------*/
  .container .cart-page .items-fill form .btn {
    height: 30px;
  }

  .container .cart-page .items-fill form .input {
    height: 31px;
    font-size: 1vw;
  }

  /*-------------------------------------------------- cart-end --------------------------------------------------*/

}

/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/
/*-------------------------------------------------- 76800000000 --------------------------------------------------*/


@media (max-width:770px) {

  /*-------------------------------------------------- 770-425-375-325 --------------------------------------------------*/
  .container .gallery-page .gallery-story {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 700px;
  }

  .container .gallery-page .gallery-story .text {
    z-index: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container .gallery-page .gallery-story img {
    position: absolute;
    width: 70%;
    height: 50%;
    opacity: .3;
  }

  /*-------------------------------------------------- foot-start --------------------------------------------------*/
  .intro-foot {
    height: auto;
  }

  .foot-about {
    flex-direction: column;
    height: auto;
  }

  .foot-social {
    height: 100px;
  }

  .foot-social .to-social {
    width: 25px;
    height: 25px;
    margin: 0 7px;
  }

  .about-row1, .about-row2, .about-row3 {
    width: 90%;
    margin: 20px 10px;
    padding: 0;
  }

  .about-row1 h2, .about-row3 h2, .about-row2 h2 {
    width: 80%;
  }

  .foot-final {
    height: 40px;
    font-size: 12px;
  }

  .foot-final h5 {
    font-size: 12px;
  }


  /*-------------------------------------------------- foot-end --------------------------------------------------*/
  /*-------------------------------------------------- 770-425-375-325-end --------------------------------------------------*/

  /*-------------------------------------------------- navbar-start --------------------------------------------------*/
  .container .navbar .navbar-in .in-left {
    display: flex;
  }

  .container .navbar .navbar-in .in-right {
    display: flex;
  }

  .container .navbar-in-out .humberger {
    display: none;
  }

  .container .navbar-in-out .Search-icon {
    display: none;

  }


  /*-------------------------------------------------- navbar-start --------------------------------------------------*/
  /*-------------------------------------------------- gallery-start --------------------------------------------------*/

  .container .navbar-in-out .navbar-in .in-right {
    width: 50%;
  }

  .container .navbar-in-out .navbar-in .in-left {
    width: 50%;
  }

  .container .gallery-page .gallery-face h1 {
    transform: translateY(-230px);

  }

  .container .gallery-page .gallery-face img {
    width: 45%;
    transform: translate(0px, 160px);
    height: 740px;
    z-index: 1;
  }

  .container .gallery-page .gallery-face {
    height: 950px;
  }

  .container .gallery-page .gallery-story {
    height: 850px;
  }

  .container .gallery-page .gallery-story p {
    font-size: 30px;
    width: 80%;
    text-align: center;
    top: 0;
  }

  .container .gallery-page .gallery-story a {
    top: 3%;
    font-size: 20px;

  }

  .container .gallery-page .gallery-story img {
    height: 50%;
    width: 40%;
  }

  .container .gallery-page .img-box-left img {
    width: 350px;


  }

  /*-------------------------------------------------- gallery-end --------------------------------------------------*/
  /*-------------------------------------------------- bijoux-start --------------------------------------------------*/
  .container .in-boutique .boutique-face img {
    width: 45%;
    height: 700px;
    z-index: 1;
  }

  .container .in-boutique .boutique-story {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 700px;
  }

  .container .in-boutique .boutique-story .text {
    z-index: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container .in-boutique .boutique-story img {
    position: absolute;
    width: 55%;
    height: 50%;
    opacity: .3;
  }

  .container .in-boutique .boutique-story {
    height: 850px;
  }

  .container .in-boutique .boutique-story p {
    font-size: 30px;
    width: 80%;
    text-align: center;
    top: 0;
  }

  .container .in-boutique .boutique-story a {
    top: 3%;
    font-size: 20px;
    width: 100%;
    text-align: center;
  }

  .container .all-product .product-box {
    height: 330px;
  }

  .container .all-product .product-box-change {
    transform: scale(.9);
    width: 25%;
  }

  .container .all-product .change-display {
    transform: scale(.9);
  }

  .container .all-product .product-box .left-side .name-category .name {
    font-size: 50px;
  }

  /*-------------------------------------------------- bijoux-end --------------------------------------------------*/
  /*-------------------------------------------------- home-start --------------------------------------------------*/
  .container .the-fill .fill-page {
    width: 120%;
    height: 120%;
  }

  .content .intro-photos .img1 img {
    height: 210px;
    left: 100px;
  }

  .content .intro-photos .img2 img {
    height: 360px;
  }

  .content .intro-photos .img3 img {
    height: 230px;
  }

  .content .intro-photos .img4 img {
    height: 360px;
  }

  /*-------------------------------------------------- home-end --------------------------------------------------*/
  /*-------------------------------------------------- details-start --------------------------------------------------*/
  .container .details-page .in-deails .img-center {
    width: 22vw;
    height: 22vw;
  }

  .container .details-page .in-deails {
    height: 550px;
  }

  .container .details-page .in-deails .details-side p {
    font-size: 14px;
  }

  .container .details-page .more-product-1 {
    height: 550px;
  }

  .container .details-page .right-fitt .img-box {
    width: 80%;
  }

  .container .details-page .right-fitt .img-box .img2 {
    width: 35%;
    height: 45%;
  }

  .container .details-page .more-product-2 {
    flex-wrap: wrap;
    height: auto;
    padding: 200px 10px 100px;
    justify-content: space-evenly;
  }

  .container .details-page .more-product-2 .img-box-0 {
    width: 30%;
    height: 350px;
    margin: 30Px 15px;
  }

  .container .details-page .left-fitt h1 {
    font-size: 7vw;
  }

  /*-------------------------------------------------- details-end --------------------------------------------------*/
  /*-------------------------------------------------- about-start --------------------------------------------------*/
  .container .about-page .beginnings h1 {
    font-size: 55px;
  }

  .container .about-page .one-latter {
    height: 680px;
  }

  .container .about-page .beginnings .in-img {
    height: 300px;

  }

  .container .about-page .beginnings .in-img p {
    padding: 30px 50px;
  }

  .container .about-page .about-us .bottom-fitt .old-img {
    width: 60%;
  }

  /*-------------------------------------------------- about-end --------------------------------------------------*/
  /*-------------------------------------------------- contact-start --------------------------------------------------*/

  .container .contact-page .body-contact {
    flex-direction: column;
  }

  .container .contact-page .body-contact .left-side-contact,
  .container .contact-page .body-contact .right-side-contact {
    width: 100%;
    height: auto;
  }

  .container .contact-page .body-contact .right-side-contact {
    margin-top: 150px;
    text-align: center;
  }

  .container .contact-page .intro-contact {
    height: 630px;
  }

  /*-------------------------------------------------- contact-end --------------------------------------------------*/
  /*-------------------------------------------------- cart-end --------------------------------------------------*/
  .container .cart-page {
    flex-direction: column;
    padding: 0;
  }

  .container .navbar-in-out .navbar-in .in-right .li.Shopify .current {
    font-size: 1.3vw;
  }

  .container .cart-page .pay-fill {
    width: 100%;
    order: 2;
    padding: 120px 15vw;

  }

  .container .cart-page .items-fill .all-products .product-box {
    height: 12vw;
  }

  .container .cart-page .items-fill {
    width: 100%;
    border: none;
    padding: 120px 15vw;
    margin: 0;
  }

  .container .cart-page .items-fill .all-products .product-box .img {
    width: 12vw;
    height: 11vw;
  }

  .container .cart-page .items-fill .all-products .product-box h3 {
    font-size: 2.5vw;
  }

  .container .cart-page .items-fill .all-products .product-box h4 {
    font-size: 2.5vw;
  }

  .container .cart-page .items-fill .all-products .product-box h5 {
    font-size: 1.3vw;
    margin-left: 50vw;
    line-height: 22px;
    padding-right: 10px;
  }

  .container .cart-page .items-fill .all-products .product-box .img .current {
    width: 3vw;
    height: 3vw;
    font-size: 2vw;
  }

  .container .cart-page .items-fill form .input {
    height: 60px;
    font-size: 2vw;
  }

  .container .cart-page .items-fill form .btn {
    height: 58Px;
    font-size: 2vw;
  }

  .container .cart-page .items-fill .ttl-box h4 {
    font-size: 3vw;
  }

  .container .cart-page .items-fill .ttl-box h3 {
    font-size: 2vw;
  }

  .container .cart-page .pay-fill .title {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5vw;
  }

  .container .cart-page .pay-fill .contact-info .title {
    font-size: 4vw;
  }

  .container .cart-page .pay-fill .payment-box legend {
    padding: 0 2vw;
    font-size: 2vw;
  }

  .container .cart-page .pay-fill .contact-info form .btn {
    margin: 20px auto;
  }

  .container .cart-page .pay-fill .payment-box .box img {
    width: auto;
    height: 2.3vw;
  }

  .container .cart-page .pay-fill .payment-box .box4 img {
    width: 8vw;
    height: 2vw;
  }

  /*-------------------------------------------------- cart-end --------------------------------------------------*/
  /*-------------------------------------------------- foot-start --------------------------------------------------*/

  .foot-about {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

  }

  .about-row1, .about-row2, .about-row3 {
    width: 40%;
  }

  .about-row1 {
    order: 2;
    width: 70%;

  }

  .about-row2 {
    order: 1;
  }

  .foot-social .to-social {
    width: 35px;
    height: 35px;
    margin: 0 10px;
  }

  /*-------------------------------------------------- foot-end --------------------------------------------------*/


}

@media (max-width:750px) {

  /*-------------------------------------------------- navbar-Start --------------------------------------------------*/
  .container .navbar .navbar-in .in-left {
    display: none;
  }

  .container .navbar .navbar-in .in-right {
    display: none;
  }

  .container .navbar-in-out .humberger {
    display: flex;
    left: 80%;
  }

  .container .navbar-in-out .Search-icon {
    display: flex;

  }

  .container .navbar-in-out .navbar-in .in-center img {
    width: 49px;
    height: 38px;
  }

  .container .navbar-in-out .navbar-out form {
    padding: 5px 10px;
  }

  .container .navbar-in-out .navbar-out form input {
    width: 100%;
    font-size: 20px;
    height: 60px;
    font-weight: 500;
    color: #444;

  }

  .container .navbar-in-out .navbar-out .all-data-Search {
    padding-left: 10px;
    padding-right: 10px;
  }

  .container .navbar-in-out .navbar-out .all-data-Search h2 {
    font-size: 18px;
    font-weight: 500;
    padding: 0 15px;
    color: #444;
  }

  /*//////////Home////////*/
  .container .the-fill .fill-content h1 {
    font-weight: 500;
  }

  /*-------------------------------------------------- navbar-end --------------------------------------------------*/
}

/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 42500000 ---------------------------------------------------------*/

@media (max-width:450px) {
  /*-------------------------------------------------- gallery-start --------------------------------------------------*/

  .container .gallery-page .All-picture .chnage-position {
    font-size: 20vw;
  }

  .container .gallery-page .gallery-face h1 {
    transform: translateY(-118px);
    font-weight: 500;
    font-size: 15vw;
  }

  .container .gallery-page .gallery-face img {
    height: 500px;
    transform: translateY(135px);
    width: 55%;
    z-index: 1;
  }

  .container .gallery-page .gallery-story img {
    width: 61%;
    height: 52%;
  }

  .container .gallery-page .gallery-face {
    height: 650Px;
  }

  .container .gallery-page .gallery-story p {
    padding: 10px;
    top: 0;
    text-align: center;
    font-size: 20px;
    width: 92%;
  }

  .container .gallery-page .gallery-story a {
    top: 3.5%;
    text-align: center;
    width: 100%;
  }

  .container .gallery-page .img-box-left img {
    width: 230px;
    transform: scale(1.1);
  }

  /*-------------------------------------------------- foot-start-425-375-325 --------------------------------------------------*/
  .intro-foot {
    height: auto;
  }

  .foot-about {
    flex-direction: column;
    height: auto;
  }

  .foot-social {
    height: 100px;
  }

  .foot-social .to-social {
    width: 25px;
    height: 25px;
    margin: 0 7px;
  }

  .about-row1, .about-row2, .about-row3 {
    width: 90%;
    margin: 20px 10px;
    padding: 0;
  }

  .about-row1 h2, .about-row3 h2, .about-row2 h2 {
    width: 80%;
  }

  .foot-final {
    height: 40px;
    font-size: 12px;
  }

  .foot-final h5 {
    font-size: 12px;
  }


  /*-------------------------------------------------- foot-end --------------------------------------------------*/
  /*-------------------------------------------------- gallery-end --------------------------------------------------*/
  /*-------------------------------------------------- bijoux-start --------------------------------------------------*/
  .container .in-boutique .boutique-face {
    height: 650px;
  }

  .container .in-boutique .boutique-face h1 {
    transform: translateY(-315px);
    font-weight: 500;
    font-size: 15vw;
  }

  .container .in-boutique .boutique-face img {
    height: 500px;
    width: 55%;
    transform: translateY(138px);
  }

  .container .in-boutique .boutique-story {
    height: 700px;
  }

  .container .in-boutique .boutique-story p {
    padding: 10px;
    top: 0;
    text-align: center;
    font-size: 20px;
    width: 92%;
  }

  .container .in-boutique .boutique-story img {
    width: 80vw;
    height: 85vw;
  }

  .container .all-product .product-box {
    height: 200px;
    margin: 50px 0;
  }

  .container .all-product .product-box .in-center img {
    width: 80px;
    height: 105px;
  }

  .container .all-product .product-box .left-side button {
    padding: 10px 22px;
    font-size: 15px;
  }

  .container .all-product .product-box .left-side .name-category .name {
    font-size: 30px;
  }

  .container .all-product .product-box .left-side .name-category .category {
    font-size: 12px;
  }

  .container .all-product-change .product-box-change {
    width: 45%;
  }

  .container .all-product-change {
    padding: 0;
  }

  .container .all-product-change .product-box-change .in-center img {
    width: 120px;
    height: 130px;
  }

  /*-------------------------------------------------- bijoux-end --------------------------------------------------*/
  /*-------------------------------------------------- home-start --------------------------------------------------*/
  .container .the-fill .fill-page {

    transform: scale(1.85);
  }

  .content .text-intro {
    margin: 50px auto;
    width: 95%;
  }

  .content .text-intro h1 {
    font-size: 4vw;

  }

  .container .the-fill .fill-content h1 {
    font-size: 14vw;
  }

  .container .the-fill .fill-content h3 {
    font-size: 3vw;
  }

  .content .intro-photos .img1 img {
    height: 180px;
    left: 50px;
  }

  .content .intro-photos .img2 img {
    height: 300px;
    left: 150px;
    transform: translateY(-180px);
  }

  .content .intro-photos .img3 img {
    height: 180px;
  }

  .content .intro-photos .img4 img {
    height: 300px;
  }

  .container .to-about {
    padding: 100px 10px;
  }

  .container .to-about h1 {
    margin: 20px 0;
  }


  .content .intro-photos .to-shop-page h1 {
    font-size: 8.5vw;
  }

  .container .to-about h4,
  .content .intro-photos .to-shop-page h4 {

    font-size: 2.5vw;
  }

  .container .to-about h1 {
    font-size: 5vw;
    width: 90%;
  }

  .content .intro-photos .to-shop-page button,
  .container .to-about button {

    font-size: 3.5vw;
  }

  /*-------------------------------------------------- home-end --------------------------------------------------*/

  /*-------------------------------------------------- details-start --------------------------------------------------*/
  .container .details-page .in-deails {
    flex-direction: column;
    height: 700px;
  }

  .container .details-page .in-deails .img-side {
    width: 100%;
    height: 35%;
  }

  .container .details-page .in-deails .details-side {
    padding: 10px;
    padding-top: 95px;
    width: 100%;
    height: 65%;
  }

  .container .details-page .in-deails .img-side img {
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
  }

  .container .details-page .in-deails .img-center {
    top: 180px;
    border-radius: 20px;
    width: 30vw;
    height: 30vw;
  }

  .container .details-page .in-deails .details-side h1 {
    font-size: 40px;
    margin-bottom: 20px;

  }

  .container .details-page .in-deails .details-side button {
    margin-top: 40px;
  }

  .container .details-page .right-fitt {
    position: absolute;
    width: 100%;
  }

  .container .details-page .right-fitt .img-box {
    left: 10%;
    height: 42%;
    width: 70%;
  }

  .container .details-page .left-fitt {
    background-color: transparent;
    width: 100%;
    z-index: 1;
    padding: 40px 10px;
    justify-content: flex-start;
    overflow: hidden;
  }

  .container .details-page .left-fitt h1 {
    margin: 10px 0 390px;
    font-size: 50px;
  }

  .container .details-page .right-fitt .img-box .img2 {
    height: 55%;
    width: 38%;
  }

  .container .details-page .more-product-1 {
    height: 650px;
  }

  .container .details-page .more-product-2 .img-box-0 {
    width: 70%;
    height: 400px;

  }

  .container .details-page .more-product-2 h1 {
    top: 3%;
  }

  /*-------------------------------------------------- details-end --------------------------------------------------*/
  /*-------------------------------------------------- about-start --------------------------------------------------*/
  .container .about-page .one-latter {
    height: 680px;
  }

  .container .about-page .beginnings .in-img {
    height: 250px;
    width: 80%;
  }

  .container .about-page .beginnings .in-img p {
    padding: 20px;
    font-size: 2.9vw;
  }

  .container .about-page .beginnings h1 {
    font-size: 45px;
  }

  .container .about-page .about-us .bottom-fitt .old-img {
    width: 80%;
    height: 250px;
  }

  .container .about-page .about-us .bottom-fitt h4 {
    font-size: 8px;
  }

  .container .about-page .about-us .top-fitt p {
    width: 65%;
  }


  /*-------------------------------------------------- about-end --------------------------------------------------*/
  /*-------------------------------------------------- story-start --------------------------------------------------*/
  .container .histoire-page .histoire-content-top h1 {
    font-size: 20vw;
    width: 90%;
  }

  .container .histoire-page .histoire-content-top h4 {
    width: 90%;
    font-size: 6vw;
  }

  .container .histoire-page .histoire-content-top h2 {
    width: 90%;
    font-size: 12vw;
  }

  .container .histoire-page .histoire-content-top p, .container .histoire-page .histoire-content-bottom p {
    width: 70%;
  }

  .container .histoire-page .histoire-content-top img {
    height: 300px;
  }

  .container .histoire-page .histoire-content-bottom img {
    width: 80%;
    height: 250px;
  }

  /*-------------------------------------------------- story-end --------------------------------------------------*/
  /*-------------------------------------------------- contact-start --------------------------------------------------*/

  .container .contact-page .intro-contact {
    height: 650px;
    position: relative;
  }

  .container .contact-page .intro-contact h1 {
    display: flex;
    flex-direction: column;
    font-size: 12vw;
    margin-top: 20px;
  }

  .container .contact-page .intro-contact h1 div {
    text-align: center;
  }

  /* .container .contact-page .intro-contact h1::after{
  content: "contact";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 10px;
} */
  .container .contact-page .body-contact .right-side-contact p {
    font-size: 25px;
    width: 70%;
  }

  .container .contact-page .body-contact .right-side-contact img {
    height: 300px;
    width: 70%;
  }

  /*-------------------------------------------------- contact-end --------------------------------------------------*/
  /*-------------------------------------------------- cart-start --------------------------------------------------*/
  .container .cart-page .items-fill {
    padding: 120px 10vw 60px;
  }

  .container .cart-page .items-fill form .input {
    height: 31px;
  }

  .container .cart-page .items-fill form .btn {
    height: 30px;
  }

  .container .cart-page .items-fill .all-products .product-box {
    margin: 30px 0;
  }

  .container .cart-page .items-fill .all-products .product-box .img {
    width: 20vw;
    height: 20vw;
  }

  .container .cart-page .items-fill .all-products .product-box .img .current {
    width: 6vw;
    height: 6vw;
  }

  .container .cart-page .items-fill .all-products .product-box h3,
  .container .cart-page .pay-fill .payment-box legend,
  .container .cart-page .items-fill .ttl-box h3 {
    font-size: 3.5vw;
  }

  .container .cart-page .items-fill .all-products .product-box h4,
  .container .cart-page .items-fill .ttl-box h4 {
    font-size: 4vw;
  }

  .container .cart-page .pay-fill .payment-box .box {
    height: 30px;
  }

  .container .cart-page .pay-fill .title {
    font-size: 8vw;
  }

  .container .cart-page .pay-fill .payment-box .box4 {
    width: 50;
  }

  .container .cart-page .pay-fill .contact-info form .input {
    font-size: 3.5vw;
  }

  .container .cart-page .items-fill form .input,
  .container .cart-page .items-fill form .btn {
    font-size: 3vw;
    height: 40px;
  }

  .container .cart-page .pay-fill h4,
  .container .cart-page .pay-fill .contact-info form .btn {
    font-size: 3vw;
  }

  .container .cart-page .items-fill .all-products .product-box h5 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 2vw;
  }

  .container .navbar-in-out .media-navbar .li.Shopify .current {
    position: absolute;
    inset: 0;
    left: 53%;
    top: -15%;
    width: 1.3vw;
    height: 1.3vw;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    font-weight: bold;
    color: #333;
    z-index: 1;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .container .all-product .noItems {
    font-size: 7vw;
  }

  .container .all-product .category-fill {
    justify-content: center;
    margin-bottom: 20px;
  }

  .container .all-product-change .category-fill {
    align-items: flex-end;
    justify-content: center;
  }

  .container .cart-page .pay-fill {
    padding: 60px 10vw;
  }

  /*-------------------------------------------------- cart-end --------------------------------------------------*/

}

/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/
/*----------------------------------------------------- 37500000 ---------------------------------------------------------*/

@media (max-width:380px) {

  /*-------------------------------------------------- gallery-start --------------------------------------------------*/
  .container .gallery-page .gallery-face h1 {
    transform: translateY(-115px);

  }

  .container .gallery-page .gallery-face img {
    height: 500px;
    transform: translateY(135px);
    width: 60%;
    z-index: 1;
  }

  .container .gallery-page .gallery-face {
    height: 650Px;
  }

  .container .gallery-page .gallery-story p {
    padding: 10px;
    top: 0;
    text-align: center;
    font-size: 20px;
    width: 95%;
  }

  .container .gallery-page .gallery-story a {
    top: 3.5%;
    text-align: center;
    width: 100%;
  }

  .container .gallery-page .img-box-left img {
    width: 230px;
  }

  .container .gallery-page .gallery-story {
    height: 600px;
  }

  /*-------------------------------------------------- gallery-end --------------------------------------------------*/
  /*-------------------------------------------------- bijoux-start --------------------------------------------------*/
  .container .in-boutique .boutique-face img {

    width: 60%;
  }

  .container .all-product .product-box {
    height: 180px;
  }

  .container .all-product .product-box .in-center img {
    width: 90px;
    height: 80px;
  }

  /*-------------------------------------------------- bijoux-end --------------------------------------------------*/
  /*-------------------------------------------------- home-start --------------------------------------------------*/
  .container .the-fill .fill-page {
    width: 132.5%;
  }

  /*-------------------------------------------------- home-end --------------------------------------------------*/
  /*-------------------------------------------------- datails-start --------------------------------------------------*/
  .container .details-page .more-product-2 h1 {
    transform: translateY(5px);
  }

  .container .details-page .in-deails .img-center {
    top: 185px;
  }

  .container .details-page .right-fitt .img-box {
    width: 80%;
  }

  .container .details-page .right-fitt .img-box .img2 {
    left: -55px;
  }

  .container .details-page .more-product-2 .img-box-0 {
    width: 65%;
    height: 370px;
  }


  /*-------------------------------------------------- datails-end --------------------------------------------------*/


}

/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/
/*----------------------------------------------------- 32000000 ---------------------------------------------------------*/


@media (max-width:320px) {

  /*-------------------------------------------------- gallery-start --------------------------------------------------*/
  .container .gallery-page .gallery-face h1 {
    font-weight: 500;
    transform: translateY(-68px);
  }

  .container .gallery-page .gallery-face img {
    height: 350px;
    transform: translateY(100px);
  }

  .container .gallery-page .gallery-face {
    height: 500Px;
  }


  .container .gallery-page .gallery-story p {
    padding: 2px;
  }

  .container .gallery-page .gallery-story a {
    top: 5%;
  }

  .container .gallery-page .img-box-left img {
    width: 190px;
  }

  /*-------------------------------------------------- gallery-end --------------------------------------------------*/
  /*-------------------------------------------------- bijoux-start --------------------------------------------------*/
  .container .in-boutique .boutique-face img {

    width: 60%;
  }

  .container .all-product .product-box {
    height: 160px;
  }

  .container .all-product .product-box .in-center img {
    height: 75px;
  }

  .container .all-product .product-box .left-side button {
    padding: 5px 10px;
    font-size: 10px;
  }

  /*-------------------------------------------------- bijoux-end --------------------------------------------------*/
  /*-------------------------------------------------- datails-start --------------------------------------------------*/
  .container .details-page .in-deails .img-center {
    top: 195px;
  }

  .container .details-page .more-product-2 .img-box-0 {
    height: 320px;
  }

  /*-------------------------------------------------- datails-end --------------------------------------------------*/
}








/*-------------------------------------------------- Media-Respensive-end --------------------------------------------------*/


.pointer-events {
  pointer-events: none;
}